<script setup>
import { reactive, ref, onMounted, computed, watch, toRefs } from 'vue';
import axios from 'axios';
import algoliasearch from 'algoliasearch/lite';
import SingleCorrespondent from './SingleCorrespondent.vue';
import savedCorrespondents from './SavedCorrespondents.vue';
import OfflineCorrespondentSearch from './OfflineCorrespondentSearch.vue';
import { storeToRefs } from 'pinia';

import { useCorrespondentList } from '@/stores/CorrespondentList';
import { useAuthStore, storeToRefs as authStoreToRefs } from "@/stores/Auth";


// Set up authentication store
const authStore = useAuthStore();
const { user, userHasMainSiteAccount } = authStoreToRefs(authStore);
const loggedIn = computed(() => !!user.value);

// Log changes to loggedIn
watch(loggedIn, (newValue) => {
    console.log('loggedIn changed:', newValue);
});


// Set up correspondent list store
const store = useCorrespondentList();
const { savedPorts, correspondentList } = storeToRefs(store);

// Correctly reference the function
const getCorrespondentList = store.getCorrespondentList;


const searchClient = algoliasearch('QI33H8CI46', '8f30cac1e37ce61c0f30027ba78ad5c1');
const indexName = 'correspondents'; // Use a string for the index name
const prefix = window?.ALGOLIA_USE_DEV_INDEXES === true ? 'dev_' : ''
const fullIndexName = `${prefix}${indexName}`;
const searchIndex = searchClient.initIndex(fullIndexName); // Initialize the search index

const state = reactive({
    searchInput: '', // Search input
    tags: [], // Tag array
    fetchedItems: [], //Results from Algolia
    nbHits: 0, // Hits counter
    isLawyer: null, // Null to cater for 3 filter states
    showSuggestions: false, // Show autocomplete
    resultsVisible: false, // Display Results
    accordionStates: {}, // Tracks individual accordions
    isSaving: false, // Sets Disabled Button state
    portErrors: {},  // Tracks errors per port
    portSaving: {},   // Tracks saving state per port
});

function currentDate() {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    return date;
}

//Accordion logic
const beforeEnter = (el) => {
    el.style.height = '0';
};

const enter = (el) => {
    el.style.height = `${el.scrollHeight}px`;
};

const beforeLeave = (el) => {
    el.style.height = `${el.scrollHeight}px`;
};

const leave = (el) => {
    el.style.height = '0';
};

function toggleAccordion(portName) {
    state.accordionStates[portName] = !state.accordionStates[portName];
}

function isAccordionOpen(portName) {
    return !!state.accordionStates[portName];
}


// Computed property to check online status
const isOnline = computed(() => navigator.onLine);


//Main Algolia Search Function

//Commercial/Lawyer filter - Needed to handle 3 states inc null/empty state
const algoliaFilters = computed(() => {
    if (state.isLawyer === true) {
        return 'is_lawyer:true';
    } else if (state.isLawyer === false) {
        return 'is_lawyer:false';
    }
    return ''; // No filter applied
});

//Query Algolia API with search query
async function performSearch() {
    const query = state.searchInput.trim() || state.tags.join(' ') || '';  // Handle empty query properly
    const filters = algoliaFilters.value;  // Use computed property for filters

    console.log('Performing search with query:', query, 'filters:', filters);

    // Return early if query is empty and not 'all'
    if (!query && query !== 'all' && !filters) {
        state.fetchedItems = [];
        state.nbHits = 0;
        state.resultsVisible = false;
        return;
    }

    // Try Search
    try {
        const response = await searchIndex.search(query === 'all' ? '' : query, { filters }); // Search with empty query if 'all'
        state.fetchedItems = response.hits; // Results
        state.nbHits = response.nbHits; // Reactive results counter
        state.resultsVisible = true;  // Show results - Make sure this is set after async call
    } catch (error) {
        console.error('Search failed:', error);
        state.fetchedItems = []; // Reset Results array
        state.resultsVisible = false; // Hide results
        state.nbHits = 0; // Reset hits on error
    }
    state.showSuggestions = false;
}

//Set Query String
function updateSearchQuery() {
    const urlParams = new URLSearchParams();
    if (state.tags.length > 0) {
        // Set 'search' to be a concatenation of all tags, separated by spaces
        urlParams.set('search', state.tags.join(' '));
    } else {
        urlParams.delete('search');  // Clear the search parameter if no tags
    }
    history.replaceState(null, '', '?' + urlParams.toString());
}

//Keyboard input function
function onInput(value) {
    state.searchInput = value;
    state.showSuggestions = value.trim().length >= 3;
}

function handleSubmit(event) {
    event.preventDefault(); // Prevent form submit
    let input = state.searchInput.trim();
    if (input) {
        state.tags = [input];  // Replace tags array with new input
        state.searchInput = '';
        performSearch();
        updateSearchQuery();  // Ensure this is called here
    }
}

function searchAll() {
    console.log('Search all clicked');
    state.searchInput = '';
    state.tags = ['all'];
    state.isLawyer = null;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('search', 'all');
    history.replaceState(null, '', '?' + urlParams.toString());
    performSearch();
}

function clearSearch() {
    state.searchInput = '';
    state.tags = [];
    state.fetchedItems = [];
    state.showSuggestions = false;
    state.isLawyer = null;  // Reset the lawyer filter
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('search');
    history.replaceState(null, '', '?' + urlParams.toString());
    state.resultsVisible = false; // Hide results immediately
}

function removeTag(tag) {
    state.tags = state.tags.filter(t => t !== tag);
    if (state.tags.length === 0) {
        state.fetchedItems = [];
        state.resultsVisible = false;
        state.isLawyer = null;  // Reset the lawyer filter when all tags are removed
    }
    updateSearchQuery();  // Ensure this is called here
    performSearch();
}

function filterLegal() {
    state.isLawyer = state.isLawyer === true ? null : true;
    performSearch();
}

function filterCommercial() {
    state.isLawyer = state.isLawyer === false ? null : false;
    performSearch();
}

function selectItem(hit) {
    state.tags = [hit.name];  // Replace tags array with selected item (as we're only allowing one tag)
    state.searchInput = '';
    performSearch();
    updateSearchQuery();  // Ensure this is called here
}

//Watches state of reactive elements and performs search operations
watch(() => [state.searchInput, state.isLawyer, ...state.tags], () => {
    performSearch();
}, { deep: true });

//Initialise on DOM ready

//Search Query Mount
onMounted(() => {
    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get('search');
    if (searchQuery) {
        if (searchQuery === 'all') {
            state.tags = ['all'];
            performSearch();
        } else {
            state.tags = [searchQuery];  // Initialise with single tag from query string
            performSearch();
        }
    }

});

//Display Results by Country and then by Port
const groupedResults = computed(() => {
    const grouped = {};
    state.fetchedItems.forEach(item => {
        const country = item.country || 'Unknown';
        const portName = item.port?.name || 'Unknown Port';
        const firstCall = item.port?.first_call_contact || 'No Contact';
        if (!grouped[country]) grouped[country] = {};
        if (!grouped[country][portName]) grouped[country][portName] = { items: [], firstCall };
        grouped[country][portName].items.push(item);
    });
    return grouped;
});


//Save Port functionality

// Prepare data for saved-correspondents component
const savedPortsArray = computed(() => savedPorts.value || {});

function handlePortAction(portName, country) {
    const isSaved = isPortSaved(portName, country);
    console.log(`Port action triggered: ${portName}, Country: ${country}, IsSaved: ${isSaved}`);

    if (isSaved) {
        removeSavedPort(portName, country);
    } else {
        savePort(portName, country);
    }
}

function handleRemovePort({ portName, country }) {
    removeSavedPort(portName, country).then(() => {
        // Update savedPortsArray to trigger reactivity in the child component
        savedPortsArray.value = { ...savedPorts.value };
    });
}


// Function to fetch a flag by country name using restcountries.com opensource API
async function fetchFlagByCountryName(countryName) {

    // try {
    //     const response = await axios.get(`https://restcountries.com/v3.1/name/${countryName}?fullText=true`);
    //     if (response.status === 200 && response.data.length > 0) {
    //         return response.data[0].flags.png || '';
    //     }
    // } catch (error) {
    //     console.error(`Error fetching flag for ${countryName}:`, error);
    // }

    return ''; // Return empty string if not found
}

// Save Port function
async function savePort(portName, country) {
    state.portSaving[portName] = true;
    state.portErrors[portName] = null;

    try {
        const response = await axios.post('/api/save-port', { portName, country });
        console.log('savePort response:', response);
        if (response.status === 200) {
            const flagUrl = await fetchFlagByCountryName(country);
            const flag = flagUrl || '/assets/correspondents/fallback-flag.png';

            // Ensure that correspondentList is defined and accessible
            if (correspondentList.value) {
                console.log('Before adding to correspondentList:', correspondentList.value);

                // Update correspondentList
                correspondentList.value.push({
                    port_country: country,
                    port_name: portName,
                    flag: flag
                });

                // Ensure reactivity
                correspondentList.value = [...correspondentList.value];

                console.log('After adding to correspondentList:', correspondentList.value);
            } else {
                console.error('correspondentList is not defined or not accessible in the current scope');
            }

            state.portSaving[portName] = false;
        } else {
            console.log('savePort error response:', response.data);
            state.portErrors[portName] = response.data.message;
        }
    } catch (error) {
        console.error('savePort exception:', error);
        if (error.response) {
            state.portErrors[portName] = error.response.data.message || error.response.data;
        } else {
            state.portErrors[portName] = error.message;
        }
    } finally {
        state.portSaving[portName] = false;
    }
}

// Remove Port function
async function removeSavedPort(portName, country) {
    state.portSaving[portName] = true;

    console.log('Port details to remove' + portName, country)

    try {
        const response = await axios.post('/api/remove-port', { portName, country });
        if (response.status === 200) {
            const ports = savedPorts.value[country];
            const index = ports.findIndex(port => port.name === portName);
            if (index > -1) {
                ports.splice(index, 1);
                if (ports.length === 0) {
                    delete savedPorts.value[country];
                }
                const correspondentIndex = store.correspondentList.findIndex(
                    item => item.port_country === country && item.port_name === portName
                );
                if (correspondentIndex > -1) {
                    store.correspondentList.splice(correspondentIndex, 1);
                }
            }
        } else {
            state.portErrors[portName] = response.data.message;
        }
    } catch (error) {
        state.portErrors[portName] = error.response ? error.response.data : error.message;
    } finally {
        state.portSaving[portName] = false;
    }
}


function isPortSaved(portName, country) {
    const portsForCountry = savedPorts.value[country];
    if (portsForCountry) {
        return portsForCountry.some(port => port.name.trim().toLowerCase() === portName.trim().toLowerCase());
    }
    return false;
}

//Saved Ports Mount
onMounted(async () => {

    console.log('OnMounted: Auth Status', loggedIn.value);

    if (loggedIn.value) {
        console.log('User is authenticated');
        try {
            console.log('Calling getCorrespondentList...');
            await getCorrespondentList();
            console.log('getCorrespondentList finished');
            if (!savedPorts.value) {
                console.error('savedPorts is still undefined or null after getCorrespondentList');
            } else {
                console.log('savedPorts after getCorrespondentList:', savedPorts.value);
            }
        } catch (error) {
            console.error('Failed to fetch saved ports:', error);
        }
    } else {
        console.log('User is not authenticated');
    }

});


watch(savedPorts, (newValue) => {
    console.log('savedPorts updated:', newValue);
});


</script>
<template>
    <div>
        <div class="banner">
            <div class="inner">
                <div class="info relative flex mt-4 md:mt-8 mb-4 items-center">
                    <title-tool-tip title="Correspondents Search">
                        <template #tooltip-content>
                            <p class="text-white">Our comprehensive network of correspondents is based in all the major ports and maritime centres worldwide and is built on years of experience and mutual trust. Working in partnership with our global offices, they provide immediate, practical advice and ongoing support, based on the latest local information.</p>
                        </template>
                    </title-tool-tip>
                </div>
            </div>
        </div>
        <div v-if="isOnline">
            <ais-instant-search :search-client="searchClient" :index-name="indexName" :stalled-search-delay="250">
                <ais-autocomplete :escape-html="true">
                    <template v-slot="{ currentRefinement, indices }">
                        <div class="autocomplete-container w-full rounded-lg p-4"
                             style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);">
                            <form @submit.prevent="handleSubmit">
                                <div class="relative flex flex-col w-full md:flex-row space-y-2 md:space-y-0 md:space-x-2 items-center">
                                    <div class="relative flex w-full flex-1 items-center bg-ns-gray rounded-lg focus:outline-none focus:ring-0">
                                        <i class="fas fa-search px-4 text-gray-400"></i>
                                        <input class="flex-1 form-input drop-shadow-none font-semibold bg-transparent py-3 rounded-lg focus:outline-none focus:ring-0"
                                               placeholder="Search by Country, Port or Company"
                                               v-model="state.searchInput"
                                               @input="onInput($event.target.value)"
                                               type="search" />
                                        <div class="bg-white absolute top-full w-full z-10"
                                             style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); border-radius: 0.5rem;">
                                            <ul v-if="state.showSuggestions && currentRefinement" class="overflow-y-auto max-h-60">
                                                <li v-for="index in indices" :key="index.indexId">
                                                    <ul>
                                                        <li v-for="hit in index.hits" :key="hit.objectID"
                                                            @click="selectItem(hit)"
                                                            class="cursor-pointer bg-white font-semibold hover:bg-ns-gray py-2 px-4">
                                                            <ais-highlight attribute="name" :hit="hit"/>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:space-x-2 w-full md:flex-row md:w-auto">
                                        <button type="submit"
                                                class="w-full mt-2 md:w-auto md:my-0 inline-block button button--green focus:outline-none">
                                            Search
                                        </button>
                                        <button type="button" @click="searchAll"
                                                class="w-full font-semibold my-4 md:w-auto md:my-0 px-4 py-2 text-ns-deep-blue border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none">
                                            View all correspondents
                                        </button>
                                        <button type="button" @click="clearSearch"
                                                class="w-auto font-semibold uppercase md:w-auto px-4 py-2 text-ns-deep-blue rounded-lg hover:text-red-600 focus:outline-none ">
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="flex md:hidden flex-col items-center mt-6 md:mt-12">
                            <a
                                :class="{
                                    'mt-0': state.resultsVisible === false,
                                    'mt-0': state.resultsVisible === true
                                }"
                                class="w-full text-center block button button--green focus:outline-none transition-all duration-300 ease-in-out"
                                href="/corresponsents/all"
                            >
                                <i class="fa-solid fa-circle-arrow-up transform rotate-180 mr-4"></i>
                                <span>Download all correspondents</span>
                            </a>

                        </div>

                        <div v-if="state.resultsVisible"
                             class="flex flex-col md:flex-row gap-4 mt-4 md:mt-8 w-full items-start md:items-center">
                            <div class="md:flex-none">
                                <h4 class="my-2">Search results for</h4>
                            </div>
                            <div class="flex-grow flex items-center">
                        <span v-for="tag in state.tags" :key="tag"
                              class="bg-ns-dark-blue rounded-lg py-2 px-4 mr-2 text-white inline-flex items-center">
                            {{ tag }}
                            <button @click="removeTag(tag)" class="ml-8 hover:text-ns-dark-green">
                                <i class="fa-regular fa-xmark"></i>
                            </button>
                        </span>
                            </div>
                        </div>
                    </template>
                </ais-autocomplete>



                <div v-if="state.resultsVisible" >
                    <p class="font-heading">Your search returned <span class="font-semibold">{{ state.nbHits }} results</span></p>
                </div>



                <slot name="config"/>

                <div v-if="state.resultsVisible"  class="toggle-container flex flex-col items-end mb-8">
                    <div class="w-full items-center justify-between md:w-1/3 2xl:w-1/4 flex gap-2">
                        <span class="text-ns-deep-blue">Filter by:</span>
                        <div class="flex gap-2">
                            <button @click="filterCommercial"
                                    :class="{'bg-ns-dark-blue rounded-lg py-1 px-4  text-white inline-flex items-center': state.isLawyer === false}"
                                    class="w-auto text-ns-deep-blue px-4 py-1 border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none ">
                                <i class="fa-kit fa-ns-apartment text-ns-deep-blue text-xl"></i>
                                <span>Commercial</span>
                            </button>
                            <button @click="filterLegal"
                                    :class="{'bg-ns-dark-blue hover:bg-ns-dark-blue!important rounded-lg py-1 px-4 text-white inline-flex items-center': state.isLawyer === true}"
                                    class="w-auto text-ns-deep-blue px-4 py-1 border border-ns-deep-blue rounded-lg hover:bg-ns-gray focus:outline-none">
                                <i class="fa-kit fa-ns-gavel text-ns-deep-blue text-xl"></i>
                                <span>Legal</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col md:space-x-8 md:flex-row">

                    <section class="correspondents-search w-full md:w-2/3 xl:w-[70%] 2xl:w-3/4">
                        <ais-hits v-if="state.resultsVisible">
                            <template v-slot="{ items }">
                                <div class="grid grid-cols-1 md:grid-cols-1 gap-6 w-full mb-0 md:mb-20">
                                    <div v-for="(ports, country) in groupedResults" :key="country">
                                        <span class="font-bold flex uppercase mb-2">{{ country }}</span>
                                        <div v-for="(portData, portName) in ports" :key="portName" class="mb-4 p-4 rounded-lg"
                                             style="box-shadow: rgba(0, 0, 0, 0.1) 2px 10px 15px -3px, rgba(0, 0, 0, 0.05) 2px 4px 6px -2px;">
                                            <button
                                                type="button"
                                                :class="['flex','items-center','justify-between','text-xl','bg-ns-gray','text-ns-deep-blue','rounded-tl-lg','rounded-tr-lg','rounded-bl-lg','rounded-br-lg','w-full','font-heading','text-left','appearance-none','transition-all','duration-300','ease-in-out','py-4','px-4','hover:bg-ns-gray/80',
                                        {'rounded-bl-none rounded-br-none mb-0': isAccordionOpen(portName)}]"
                                                @click="toggleAccordion(portName)">
                                            <span>
                                                <i class="fa-kit fa-ns-anchor text-2xl mr-2"></i>
                                                <strong>{{ portName }}</strong>
                                            </span>
                                                <i :class="{'scale-y-100': !isAccordionOpen(portName),
                                                    '-scale-y-100': isAccordionOpen(portName),
                                                }"
                                                   class="fa-sharp fa-chevron-down transform transition-transform duration-300"/>
                                            </button>
                                            <Transition
                                                v-on:before-enter="beforeEnter"
                                                v-on:enter="enter"
                                                v-on:before-leave="beforeLeave"
                                                v-on:leave="leave">
                                                <div v-if="isAccordionOpen(portName)"
                                                     class="accordion-content overflow-hidden transition-all duration-300 ease-in-out">
                                                    <div v-if="loggedIn" class="bg-ns-gray pb-4 pt-2 px-4 rounded-br-lg rounded-bl-lg text-ns-deep-blue">
                                                        <hr class="mx-4 mb-4 border-white">
                                                        <button @click="handlePortAction(portName, country)"
                                                                :disabled="state.portSaving[portName]"
                                                                :class="{'text-ns-deep-blue': !isPortSaved(portName, country), 'text-ns-dark-orange': isPortSaved(portName, country)}">
                                                            <i :class="{'fa-solid fa-plus mr-2': !isPortSaved(portName, country), 'fa-solid fa-plus mr-2 -rotate-45': isPortSaved(portName, country)}"></i>
                                                            <span v-if="state.portSaving[portName]">Processing...</span>
                                                            <span v-else-if="isPortSaved(portName, country)">Remove {{ portName }} from your personalised list</span>
                                                            <span v-else>Save {{ portName }} to your personalised list</span>
                                                        </button>
                                                        <div v-if="state.portErrors[portName]" class="text-red-500">
                                                            Error: {{ state.portErrors[portName] }}
                                                        </div>
                                                        <div v-if="state.saveError" class="text-red-500">
                                                            Error: {{ state.saveError }}
                                                        </div>
                                                    </div>
                                                    <div v-if="portData.firstCall === 1 || portData.firstCall === 2">
                                                        <div class="rounded-lg border-2 mt-6 border-ns-dark-orange w-full p-6 items-center">
                                                            <div class="w-full md:items-center md:space-x-4 flex flex-col md:flex-row text-ns-deep-blue justify-between">
                                                                <div>
                                                                    <img src="/assets/correspondents/first-call.png" alt="First Call" class="max-w-[160px]">
                                                                </div>
                                                                <div>
                                                                    <p>{{ portData.firstCall === 1 ? 'South Coast US Ports' : 'East / West US Coast Ports' }}</p>
                                                                    <p class="font-bold">{{ portData.firstCall === 1 ? 'Shuman Consulting Services' : 'Hudson Tactix' }}</p>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <span class="font-bold mr-1">Tel:</span>
                                                                        <a :href="portData.firstCall === 1 ? 'tel:+1 281 486 5511' : 'tel:+1 856 342 7500'">
                                                                            {{ portData.firstCall === 1 ? '+1 281 486 5511' : '+1 856 342 7500' }}
                                                                        </a>
                                                                    </div>
                                                                    <div>
                                                                        <span class="font-bold mr-1">Email:</span>
                                                                        <a :href="portData.firstCall === 1 ? 'mailto:firstcall@scslp.com' : 'mailto:firstcall@hudsontactix.com'">
                                                                            {{ portData.firstCall === 1 ? 'firstcall@scslp.com' : 'firstcall@hudsontactix.com' }}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex flex-col text-ns-deep-blue">
                                                                <p>For matters involving the disembarkation of crew for medical treatment in the USA</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SingleCorrespondent
                                                        v-for="item in portData.items"
                                                        :key="item.objectID"
                                                        :correspondent="item"/>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </ais-hits>
                        <div :class="['w-full lg:w-2/3',{'mt-20': state.resultsVisible === false, 'hidden': state.resultsVisible === true}]">
                            <p>If you spot anything with our Correspondent data that looks incorrect or needs updating please
                                let us know</p>
                            <a href="mailto:neil.watson@north-standard.com"
                               :class="['w-auto text-center inline-block mt-2 button button--green focus:outline-none focus:ring-2 transition-all duration-300 ease-in-out']">
                                Request Update
                            </a>
                        </div>
                    </section>

                    <div class="w-full md:w-1/3 xl:w-[30%] 2xl:w-1/4 space-y-8">
                        <div class="hidden md:flex flex-col items-center mt-10">
                            <a
                                :class="{
                                    'mt-20': state.resultsVisible === false,
                                    'mt-0': state.resultsVisible === true
                                }"
                                class="w-full text-center block button button--green focus:outline-none focus:ring-2 focus:ring-opacity-50 transition-all duration-300 ease-in-out"
                                href="/corresponsents/all"
                            >
                                <i class="fa-solid fa-circle-arrow-up transform rotate-180 mr-4"></i>
                                <span>Download all correspondents</span>
                            </a>
                        </div>
                        <div class="my-8 bg-ns-gray flex flex-col rounded-lg px-8 py-6">

                            <div v-if="loggedIn">
                                <saved-correspondents
                                    :saved-ports="savedPortsArray" @removePort="handleRemovePort"
                                ></saved-correspondents>
                            </div>
                            <div v-else>
                                <h5 class="mt-0 mb-6">Saved Correspondents</h5>
                                <a href="/login" class="text-ns-deep-blue">Please log in to view <i class="fa fa-chevron-right ml-1"></i></a>.
                            </div>
                        </div>
                        <div class="px-8 py-6 text-white rounded-lg bg-gradient-to-br from-ns-deep-purple from-30% to-ns-deep-blue">
                            <p class="mt-0 text-white">For all enquiries regarding correspondents, please contact: </p>
                            <div class="flex">
                                <img class="w-[72px] h-[72px]" src="/assets/correspondents/neil-watson.png" alt="Neil Watson">
                                <div class="ml-2">
                                    <p class="font-bold text-white my-0">
                                        Neil Watson
                                    </p>
                                    <p class="my-0 text-white">
                                        Director - Correspondent Liaison & Performance
                                    </p>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mt-4">
                                    <p class="my-0">
                                        <span class="font-bold text-white">Tel:</span> <a class="text-white" href="tel:+441912325221">+44 191 232 5221</a>
                                    </p>
                                    <p class="my-0">
                                        <span class="font-bold text-white">Email:</span> <a class="text-white" href="mailto:neil.watson@north-standard.com">neil.watson@north-standard.com</a>
                                    </p>
                                    <p class="my-0">
                                        <span class="font-bold text-white">Office:</span> <a class="text-white" href="/offices?location=newcastle">UK (Newcastle)</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="my-4 px-8 py-6 border-2 border-ns-dark-orange text-ns-deep-blue rounded-lg">
                            <img class="max-w-[160px]" src="/assets/correspondents/first-call.png" alt="">
                            <p>
                                For matters involving the disembarkation of crew for medical treatment in the USA.
                            </p>
                            <a class="font-bold" href="https://north-standard.com/first-call" target="_blank">Find out more <i class="fa fa-chevron-right ml-1"></i></a>
                        </div>
                        <div class="my-4 px-8 py-6 bg-ns-gray rounded-lg">
                            <img class="max-w-[120px]" src="/assets/correspondents/ig-logo.png" alt="">
                            <p>
                                Access the International Group of P&I Clubs' Guidelines for Correspondents
                            </p>
                            <a class="font-bold" href="https://static.igpandi.org/igpi_website/media/article_attachments/Correspondents_Guidelines_-_2022.pdf">View the guidelines here <i class="fa fa-chevron-right ml-1"></i></a>
                        </div>
                        <div :class="['my-4 w-full lg:w-2/3', {'hidden': state.resultsVisible === false, 'my-4': state.resultsVisible === true}]">
                            <p>If you spot anything with our Correspondent data that looks incorrect or needs updating please let us know</p>
                            <a href="mailto:neil.watson@north-standard.com"
                               class="w-auto text-center inline-block mt-2 button button--green focus:outline-none focus:ring-2 transition-all duration-300 ease-in-out">
                                Request Update
                            </a>
                        </div>
                        <div class="my-4 px-0  rounded-lg">
                            <div>
                                <accordion title="Read full terms">
                                    <template #default>
                                        <p class="text-white">The information above is compiled from the records of NorthStandard Limited and/or NorthStandard EU DAC as of {{currentDate()}}.</p>

                                        <p class="text-white">NorthStandard Limited and NorthStandard EU DAC do not appoint agents, but use the services of the overseas correspondents listed above, where needed. Our correspondents are either experts (marked with a Commercial icon) or lawyers (marked with a Legal icon).</p>

                                        <p class="text-white">If members and/or masters are facing an issue and don’t have time to contact us first, they should make direct contact with the appropriate correspondent. Please note that while we try our best to provide correspondents’ e-mail addresses, they may not read the message immediately, especially during out-of-office hours.</p>

                                        <p class="text-white">Always follow up any urgent messages requiring immediate action by phoning the recipient to ensure they have received it safely.</p>

                                        <p class="text-white">You must also tell us about all appointments made directly as soon as possible.</p>

                                        <p class="text-white">Please note:</p>

                                        <ul class="text-white">
                                            <li>Correspondents are not agents for service of process upon NorthStandard Limited and NorthStandard EU DAC.</li>
                                            <li>NorthStandard Limited and NorthStandard EU DAC are not guarantors under the United States Oil Pollution Act of 1990.</li>
                                        </ul>
                                    </template>
                                </accordion>
                            </div>
                        </div>
                    </div>

                </div>
            </ais-instant-search>
        </div>
        <div v-else>
            <OfflineCorrespondentSearch />
        </div>

    </div>
</template>
